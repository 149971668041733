/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Log } from '../../../gql/generated/graphql';
import { Chips, Country, GenAiApplication, IsValid, LogStatus, Text } from '../../../ui-kit';
import { dateStringISOToFormatted, isNumberArray, isObjectsArray, isStringArray, millisecondToReadable } from '../../../utils';
import { ActivityMonitorDetailsStyle } from './ActivityMonitorDetails.css';

type Details = {
    key: keyof Log;
    label: string;
    transform?: (value: any, ...args: any[]) => string | JSX.Element;
}

const details: Details[] = [
    { key: 'time', label: 'Time', transform: dateStringISOToFormatted },
    { key: 'user', label: 'User' },
    { key: 'userGroups', label: 'User Groups', transform: (value) => <Chips value={value} basedOnWidth={false} flexWrap /> },
    { key: 'appName', label: 'Connector Name' },
    { key: 'genAiApplication', label: 'GenAI Application', transform: (value) => <GenAiApplication value={value} /> },
    { key: 'isValid', label: 'Verdict', transform: (value) => <IsValid value={value} iconOnly={false} /> },
    { key: 'action', label: 'Action', transform: (value) => <LogStatus value={value} iconOnly={false} /> },
    { key: 'violations', label: 'Violations', transform: (value) => <Chips value={value} basedOnWidth={false} flexWrap /> },
    { key: 'violatingFindings', label: 'Findings', transform: (value, isEntityHidden: boolean = false) => <RenderContent obj={value || {}} isEntityHidden={isEntityHidden} /> },
    { key: 'latency', label: 'Latency', transform: (value) => millisecondToReadable(value) },
    { key: 'ipAddress', label: 'IP Address' },
    { key: 'country', label: 'Country', transform: (value) => <Country value={value} /> },
    { key: 'policyName', label: 'Policy Name' },
    { key: 'extensionData', label: 'Extension Data', transform: (value) => <RenderContent obj={value || {}} /> },
    { key: 'sourceData', label: 'Source Data', transform: (value) => <RenderContent obj={value || {}} /> },
    { key: 'conversationId', label: 'Conversation ID' },
    { key: 'promptResponseId', label: 'Prompt Response ID' }
]

type IProps = {
    logData: Log;
    canViewText: boolean;
};

const ActivityMonitorDetails: React.FC<IProps> = (props) => {
    const { logData, canViewText } = props;

    const [isExtensionDataHidden, setIsExtensionDataHidden] = React.useState(false);

    useEffect(() => {
        const sourceDataType = logData?.sourceData?.type;
        if (sourceDataType === 'Extension') {
            setIsExtensionDataHidden(true);
        } else {
            setIsExtensionDataHidden(false);
        }
    }, [logData.sourceData])

    return (
        <div>
            <div>
                {details.map((detail: Details) => {
                    const { transform, key, label } = detail;

                    let value = logData[key];

                    if (transform) {
                        switch (key) {
                            case 'violatingFindings': {
                                let isEntityHidden = !canViewText;
                                value = transform(logData[key], isEntityHidden);
                                break;
                            }
                            default:
                                value = transform(logData[key]);
                                break;
                        }
                    }

                    if (key === 'extensionData' && isExtensionDataHidden) return null;

                    return (
                        <div key={key} css={ActivityMonitorDetailsStyle.keyValueRow}>
                            <Text variant='monoSmall' textCss={ActivityMonitorDetailsStyle.keyMinWidth}>{label}</Text>
                            <Text textCss={css`flex: 1;`}>{value}</Text>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ActivityMonitorDetails;

const RenderContent: React.FC<{ obj: { [key: string]: any }, isEntityHidden?: boolean }> = ({ obj, isEntityHidden }) => {
    return (
        <React.Fragment>
            {Object.entries(obj).map(([key, value]) => {
                if (value === null) return null;
                if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    return <div key={key} css={ActivityMonitorDetailsStyle.keyValueRow}>
                        <Text textCss={ActivityMonitorDetailsStyle.keyMinWidth} color="black-70" variant='monoSmall'>{key}</Text>
                        {(key.toLowerCase() === 'entity' && isEntityHidden) ?
                            <Text css={ActivityMonitorDetailsStyle.hiddenText}>Hidden</Text>
                            : <Text pre>{String(value)}</Text>
                        }
                    </div>;
                } else if (isStringArray(value) || isNumberArray(value)) {
                    return (
                        <div key={key} css={ActivityMonitorDetailsStyle.keyValueRow}>
                            <Text textCss={ActivityMonitorDetailsStyle.keyMinWidth} color="black-70" variant='monoSmall'>{key}</Text>
                            {<Chips basedOnWidth={false} flexWrap value={value.map(x => x.toString())} />}
                        </div>
                    );
                } else if (isObjectsArray(value)) {
                    return (
                        <div key={key} css={ActivityMonitorDetailsStyle.objectContainer}>
                            <Text variant='mono'>{key}</Text>
                            {value.map((item, index) => (
                                <div css={value.length > 1 && ActivityMonitorDetailsStyle.objectContainer} key={key + index}>
                                    <RenderContent key={key + index} obj={item} isEntityHidden={isEntityHidden} />
                                </div>
                            ))}
                        </div>
                    );
                } else if (typeof value === 'object') {
                    return (
                        <div key={key}>
                            <Text variant='mono'>{key}</Text>
                            <RenderContent obj={value} isEntityHidden={isEntityHidden} />
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </React.Fragment>
    );
};
